* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #172554; /* Same or similar shade as bg-blue-950 */
}

body {
  font-family: sans-serif;
  overflow-y: scroll; /* ensures a scrollbar is present when needed */
}
